.div-detail {
  background-color: #d5f2ff;
  padding: 1rem;
  margin-bottom: 0px;
  border-radius: 10px;
}

.icon-tab {
  color: #30acdc;
}
.arrow-down {
  color: #30acdc;
}
