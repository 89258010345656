@import url("https://fonts.googleapis.com/css?family=Montserrat:600");
@import url("https://fonts.googleapis.com/css?family=Montserrat:800");
@import url("https://fonts.googleapis.com/css?family=Montserrat");
// body {
//   font-family: "Montserrat", sans-serif;
//   background: linear-gradient(270deg, #ffead1, #ffb6bd, #e0b5ff);
//   background-size: 150% 150%;
//   animation: backgroundAnimation 10s ease infinite;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100vw;
//   height: 100vh;
//   margin: 0;
// }
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 60px !important;
  width: 30px !important;
}

.line-bottom {
  border-top: 1px solid #3e4042;
}
.profile {
  animation: loadProfile 0.6s ease-in-out;
  animation-fill-mode: both;
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  position: relative;
  // max-height: 750px;
  max-width: 96%;
  margin-top: 2rem;
}

.MuiTab-textColorSecondary.Mui-selected {
  color: #30acdc !important;
}

.makeStyles-appbar-34 {
  background-color: rgb(255, 255, 255) !important;
  box-shadow: none !important;
}
.MuiTabs-root {
  width: 100%;
}
.profile-bg {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 10px;
  background: white;
  box-shadow: 0 30px 50px -20px rgba(14, 0, 47, 0.21);
  width: 100%;
  height: calc(100% - 110px);
  z-index: -1;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
}

.profile-image {
  max-width: 300px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 1px solid rgb(173 210 232);
  box-shadow: 18px 19px 0px -8px rgb(173 210 232);
  animation: loadProfileImage 1s ease-in-out 0.5s;
  // width: 75%;
  max-height: 225px;
  border-radius: 15px;
  background-size: cover;
  background-position: center;
}

// .profile-image::before {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   border-radius: 10px;
//   //   background-color: #ff2256;
//   opacity: 0.8;
//   mix-blend-mode: screen;
// }

.camera {
  color: #ffff;
  position: absolute;
  bottom: 28px;
  left: 28px;
  font-size: 1.3rem;
}

.profile-info {
  animation: loadProfileImage 1s ease-in-out 0.5s;
  margin-top: 120px;
  padding: 2%;
  position: relative;
  text-align: left;
  padding-left: 10%;
}

.profile-info h1 {
  font-size: 3rem;
  font-weight: 800;
  margin: 0.7rem;
  position: absolute;
  animation-fill-mode: both;
}

h1.first-name {
  animation: titleEffect 1s cubic-bezier(0, 0.2, 0.4, 1);
  top: -115px;
  left: -85px;
}

h1.second-name {
  animation: titleEffect 1s cubic-bezier(0, 0, 0.3, 1);
  top: -50px;
  left: -45px;
}

.profile-info h2 {
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.2rem;
  margin-top: 0;
  margin-bottom: 5%;
}

.social-media-icons a,
.profile-info h2 {
  color: #f63d47;
}

.profile-info p {
  line-height: 1.5rem;
}

.social-media-icons {
  display: flex;
}

.social-media-icons a {
  margin-top: 7%;
  font-size: 1.2rem;
  flex: auto;
  text-align: center;
}

.camera,
.social-media-icons a {
  transition: text-shadow 0.5s ease;
}

.camera:hover,
.social-media-icons a:hover {
  text-shadow: 0px 5px 15px rgba(255, 0, 47, 0.45);
}

.statistics {
  margin-right: 10px;
  margin-left: 10px;
  line-height: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.statistics p {
  margin: 3%;
  flex: auto;
  color: #f63d47;
}

.statistics p strong {
  font-size: 1.4rem;
  color: #000;
  font-weight: 200;
  margin-right: 0.3rem;
}

.icon {
  background: transparent no-repeat center;
  background-size: contain;
  background-origin: content-box;
  width: 60px;
  height: 60px;
  padding: 15px;
  border: none;
  transition: transform 0.5s ease;
}

.icon:hover {
  transform: scale(0.9);
}

.arrow {
  flex: 0 1 75px;
  background-image: url("https://zephyo.github.io/22Days/code/3/graphics/arrow.svg");
}

.right {
  transform: rotate(180deg);
}

.right:hover {
  transform: scale(0.9) rotate(180deg);
}

.close {
  background-image: url("https://zephyo.github.io/22Days/code/3/graphics/close.svg");
  position: absolute;
  top: 5px;
  right: 10px;
}

@media only screen and (max-aspect-ratio: 4/7) and (max-width: 600px) {
  .profile {
    margin: 3%;
    height: 97%;
  }
  .container {
    height: 86%;
    flex-direction: column;
  }
  // .profile-image {
  //   height: 40%;
  //   width: calc(100% - 90px);
  // }
  .profile-bg {
    width: 100%;
  }
  h1.first-name {
    left: 10px;
  }
  h1.second-name {
    left: 60px;
  }
}

@media screen and (min-aspect-ratio: 4/7) {
  .profile {
    margin-left: 8%;
    margin-right: 8%;
  }
}

@keyframes backgroundAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes loadProfile {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes loadProfileImage {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes titleEffect {
  from {
    opacity: 0;
    transform: translateX(-75px);
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}

/* Timeline Container */
.timeline {
  background: #fff;
  margin: 20px auto;
  padding: 20px;
  max-height: 428px;
  overflow: auto;
}

.timeline-book {
  background: #fff;
  margin: 20px auto;
  padding: 20px;
  max-height: 856px;
  overflow: auto;
}

/* Outer Layer with the timeline border */
.outer {
  border-left: 2px solid rgb(151, 151, 151);
}

/* Card container */
.cardtime {
  position: relative;
  margin: 0 0 20px 20px;
  padding: 10px;
  cursor: pointer;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(230, 230, 230);
  color: rgb(59, 59, 59);
  border-radius: 8px;
  max-width: 400px;
  // transition: 0.3s;
  box-shadow: rgba(125, 125, 125, 0.15) 1.95px 1.95px 2.6px;
}
.cardtime.active {
  background: #d4f2ff !important;
}

.cardtime:hover {
  background: rgb(236, 253, 255);
  border-bottom: 2px solid #89e9ff;
  color: rgb(41, 41, 41);
  transition: 0.3s;
  transform: scale(1.015);
}

// :target {
//   background: rgb(236, 253, 255);
//   border-bottom: 2px solid #89e9ff;
//   color: rgb(41, 41, 41);
//   transition: 0.3s;
//   transform: scale(1.015);
// }

.cardtime:focus {
  background: rgb(236, 253, 255);
  border-bottom: 2px solid #89e9ff;
  color: rgb(41, 41, 41);
}

/* Information about the timeline */
.info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Title of the card */
.title {
  color: #000000;
  position: relative;
}

/* Timeline dot  */
.title::before {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  background: #30acdc;
  border-radius: 999px;
  left: -41px;
  top: 30px;
  border: 1px solid #30acdc;
}

.detail-inside {
  display: none;
}

.textdetail:hover .detail-inside {
  display: block;
  transition: 0.3s;
}

// .overlay {
//   background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNzhJV3XJfPqf_txRnqYivCCWtJtfQ5o28Bt1NDrKrQhLt5eW8jpnCStBerRmX1JDjPSQ&usqp=CAU");
//   background-repeat: no-repeat;
// }
